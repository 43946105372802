<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')"
        >Сохранить</el-button
      >
      <el-button size="small" type="primary" @click="SaveAndComeBack('form')"
        >Сохранить и вернуться</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      :class="$style.promoCodeEditForm"
      ref="form"
      label-position="right"
      label-width="240px"
    >
      <el-form-item label="Код" prop="code">
        <el-input :value="form.code" @input="handleCodeInput"></el-input>
      </el-form-item>
      <el-form-item label="Название акции" prop="adName">
        <el-input v-model="form.adName"></el-input>
      </el-form-item>
      <el-form-item label="Дата окончания" prop="finishAt" :rules="rules.date">
        <el-date-picker
          v-model="form.finishAt"
          type="date"
          placeholder="Выберите день"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Тип" prop="type">
        <el-select
          :class="$style.select"
          v-model="form.type"
          placeholder="Выберите тип промокода"
        >
          <el-option
            v-for="(item, index) in $options.PROMOCODE_TYPE_DISPLAY"
            :key="item + index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Значение" prop="value" :rules="rules.value">
        <el-input-number
          v-model.number="form.value"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item
        label="Мин. Сумма заказа для использования"
        prop="minPriceToApply"
      >
        <el-input-number
          v-model.number="form.minPriceToApply"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="Кол-во использований" prop="maxQuantity">
        <el-input-number
          v-model.number="form.maxQuantity"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="Активный" prop="isActive">
        <el-checkbox v-model="form.isActive"></el-checkbox>
      </el-form-item>
      <el-form-item label="Применим к товарам со скидкой" prop="isDiscountable">
        <el-checkbox v-model="form.isDiscountable"></el-checkbox>
      </el-form-item>
      <el-form-item label="Ассортименты" prop="assortmentIds">
        <el-select
          v-model="form.assortmentIds"
          v-el-select-loadmore="loadMoreAssortments"
          :remote-method="searchAssortments"
          value-key="id"
          filterable
          clearable
          remote
          multiple
          placeholder="Выберите ассортименты"
          :class="$style.select"
        >
          <el-option
            v-for="item in assortments"
            :key="item?.id"
            :label="item?.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <TextEditor :value.sync="form.description" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import { debounce } from '@/helpers/debounce.js'
import { formatInputDate } from '@/helpers'
import {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
} from '@/constants/promocodeTypes'

import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
  components: {
    TextEditor,
  },
  async created() {
    await Promise.all([await this.getAdPromo(), await this.getAssortments()])
    if (this.form?.id && this.form?.isHaveAssortments) {
      await this.getAssortments(this.form.id)
    }
    this.assortmentsSearch = debounce(this.getAssortments, 500)
  },
  data() {
    return {
      assortments: [],
      assortmentsOptions: {
        limit: 10,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        code: '',
        adName: '',
        description: '',
        isActive: false,
        isDiscountable: false,
        value: 0,
        minPriceToApply: 0,
        maxQuantity: 0,
        finishAt: null,
        assortmentIds: [],
        type: '',
      },
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date()
        },
      },
      rules: {
        code: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        adName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        date: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            message: 'Дата окончания должна быть не раньше завтрашней даты',
            validator: this.validateDateValue,
          },
        ],
        value: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            message: 'Значение промокода в процентах не может быть больше 100',
            validator: this.validatePromoValue,
          },
        ],
        type: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    validateDateValue(rule, value, callback) {
      const current = new Date()
      if (value < current) {
        return callback(false)
      }
      callback()
    },
    validatePromoValue(rule, value, callback) {
      if (this.form.type == PROMOCODE_TYPE.PERCENTAGE && value > 100)
        return callback(false)
      return callback()
    },
    async getAdPromo() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.PaymentServiceCore.AdPromosActions.getById(
          this.$route.params.id,
        )
      if (error) return
      this.form = {
        ...value,
        assortmentIds: [],
      }
      loading.close()
    },
    async getAssortments(byPromoId) {
      const loading = this.$loading({
        lock: true,
      })

      const query = { ...this.assortmentsOptions }
      if (byPromoId) {
        query.promocodeId = this.form?.id
      }
      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList(query)

      loading.close()

      if (error) return
      if (byPromoId) {
        this.form.assortmentIds = value.data
        return
      }
      this.assortments = [...this.assortments, ...value.data]
      this.assortmentsOptions.total = value.meta.count
    },
    loadMoreAssortments() {
      const { page, limit, total } = this.assortmentsOptions
      if (limit * page < total) {
        this.assortmentsOptions.page++
        this.getAssortments()
      }
    },
    async searchAssortments(search) {
      this.assortmentsOptions.page = 1
      this.assortmentsOptions.search = search
      this.assortments = []
      await this.assortmentsSearch()
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const date = new Date(this.form.finishAt)

          date.setUTCHours(21, 0, 0, 0)

          this.form.finishAt = date.toISOString()

          const data = {
            ...this.form,
            assortmentIds: this.form.assortmentIds?.map((e) => e?.id) ?? [],
          }
          const result =
            await delivery.PaymentServiceCore.AdPromosActions.update(
              this.$route.params.id,
              data,
            )
          if (result.error) {
            alert('Ошибка редактирования промокода: ' + result.error)
          } else {
            alert('Промокод успешно изменен')
          }
          loading.close()
        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
    formatInputDate(date) {
      return date == null ? '' : formatInputDate(date)
    },
    handleCodeInput(event) {
      this.form.code = event.toUpperCase()
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.promos {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  label {
    margin: 0;
  }
  .promoCodeEditForm {
    margin-top: 0.625rem;
  }
}
</style>
